import Router from 'next/router';
import routes, { getRoutesAs } from '@Routes';
import { TOGGLE_VEHICLE_MORE_INFO_MODAL } from '@Reducers/modals/actions';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { RedirectionInfo } from '@Reducers/vehicle/models';
import { getBaseURL } from '@Network/index';
import { GoToUrl } from '@Helpers/ExternalURLs';
import { CATALOGUE_PAGE_SOURCES, MATERIAL_PARTS_OIL_MAINGROUP_ID } from '@Constants/catalog';
import { VehicleMoreInfoType } from '@Constants/vehicle';
import { WindowTargetAttributes } from '@Constants/common';
import { CatalogueGroupTypes } from '@Constants/webparts';

export const redirectBaumusterPage = async () => {
	const { market } = Router.query;
	await Router.push(routes.CATALOGUE, getRoutesAs(routes.CATALOGUE, { market }));
};

export const redirectCataloguePartsPageViaVIN = async vin => {
	await Router.push(
		{
			pathname: getRoutesAs(routes.CATALOGUE_PARTS, Router.query),
			query: {
				...Router.query,
				pageSource: CATALOGUE_PAGE_SOURCES.BAUMUSTER,
				vin,
			},
		},
		undefined,
		{ shallow: true }
	);
};

export const redirectCataloguePartsPage = ({
	modelName,
	mainGroup,
	subGroup,
	partNumber,
	catalog,
	aggModelId,
	aggProductId,
	subGroupModuleId = null,
	vin = null,
	model = null,
}: {
	modelName: string;
	mainGroup: string;
	subGroup: string;
	partNumber: string;
	catalog: string;
	aggModelId: string;
	aggProductId: string;
	subGroupModuleId?: string;
	vin?: string;
	model?: string;
}) => {
	const query = {
		pageSource: CATALOGUE_PAGE_SOURCES.BAUMUSTER,
		...(vin ? { vin } : { model }),
		...(modelName && { modelName }),
		...(catalog && { catalog }),
		aggModelId,
		aggProductId,
		mainGroup,
		subGroup,
		partNumber,
		subGroupImageIndex: 0,
		subGroupModuleId,
	};
	Router.push(
		{
			pathname: getRoutesAs(routes.CATALOGUE_PARTS, Router.query),
			query,
		},
		undefined,
		{ shallow: true }
	);
};

export const redirectCatalogueFromValidation = ({
	vin,
	redirectionInfo,
	partNumber,
}: {
	vin: string;
	redirectionInfo: RedirectionInfo;
	partNumber: string;
}) => {
	const { market } = Router.query;
	const marketCode = market.toString().toLowerCase();

	if (redirectionInfo) {
		const { modelId, aggProductId, navContext } = redirectionInfo;
		const query = {
			pageSource: CATALOGUE_PAGE_SOURCES.PARTS_VALIDATION,
			...(vin && { vin }),
			...(modelId && { modelId }),
			aggProductId,
			mainGroup: (navContext || []).find(group => group.type === CatalogueGroupTypes.MAIN_GROUP).id,
			subGroup: (navContext || []).find(group => group.type === CatalogueGroupTypes.SUB_GROUP).id,
			subGroupImageIndex: 0,
		};
		const path = `${getBaseURL()}${routes.CATALOGUE}/parts?vin=${query.vin}&modelId=${query.modelId}&aggProductId=${
			query.aggProductId
		}&mainGroup=${query.mainGroup}&subGroup=${query.subGroup}&subGroupImageIndex=${query.subGroupImageIndex}`;

		GoToUrl(path.replace('[market]', marketCode), WindowTargetAttributes.BLANK);
	} else {
		GoToUrl(
			`${getBaseURL()}${routes.CATALOGUE}?modelIdentificationId=${partNumber}`.replace('[market]', marketCode),
			WindowTargetAttributes.BLANK
		);
	}
};

export const redirectMaterialPartsPage = (
	mainGroup: string,
	catalog: string,
	aggModelId: string,
	aggProductId: string,
	subGroup: string,
	vin?,
	model?
) => {
	const query = {
		...(vin ? { vin } : { model }),
		mainGroup,
		catalog,
		aggModelId,
		aggProductId,
		subGroup,
	};
	Router.push(
		{
			pathname: getRoutesAs(routes.CATALOGUE_MATERIAL_PARTS, Router.query),
			query,
		},
		undefined,
		{ shallow: true }
	);
};

export const redirectToPart = (mainGroup: string, subGroup: string, partNumber: string, vin?, model?) => {
	const query = {
		pageSource: CATALOGUE_PAGE_SOURCES.BAUMUSTER,
		...(vin ? { vin } : { model }),
		mainGroup,
		subGroup,
		partNumber,
		subGroupImageIndex: 0,
	};
	Router.push(
		{
			pathname: getRoutesAs(routes.CATALOGUE_PARTS, Router.query),
			query,
		},
		undefined,
		{ shallow: true }
	);
};

export const redirectVehiclePartCatalogue = async vin => {
	const { market } = Router.query;
	await Router.push(routes.CATALOGUE_PARTS, getRoutesAs(routes.CATALOGUE_PARTS, { market, vin }));
};

export const redirectWorkingOnVehicle = async vin => {
	const { market } = Router.query;
	await Router.push({ pathname: getRoutesAs(routes.CATALOGUE_REPAIR, { market }), query: { vin } });
};

export const redirectPPVProductPage = async () => {
	const { market } = Router.query;
	await Router.push({ pathname: getRoutesAs(routes.PAY_PER_VIN, { market }) });
};

export const redirectToOils = (e = undefined) => {
	if (e !== undefined) PushDataToTagManagerForButtons(e?.target.id, window.location.href);
	const store = getOrCreateStore({});
	const { dispatch, getState } = store;
	const { vehicleMoreInfoType } = getState().modals;
	if (vehicleMoreInfoType !== VehicleMoreInfoType.NOT_SET) dispatch(TOGGLE_VEHICLE_MORE_INFO_MODAL());
	redirectMaterialPartsPage(MATERIAL_PARTS_OIL_MAINGROUP_ID, '', '', '', '', Router.query.vin as string);
};
