import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import Icon, { IconType, IconVariant } from '@isp/icon';
import AdvancedDropdown, { DropdownOption } from '@isp/advanced-dropdown/AdvancedDropdown';
import TextButton, { HoverTypes, TextColors } from '@isp/text-button';
import { AppDispatch, RootState } from '@Redux';
import {
	GET_NON_SAVED_VEHICLE_DETAILS,
	GET_VEHICLE_DETAILS,
	GET_VEHICLE_INFO,
	SET_RECENTLY_OPENED_VEHICLES,
} from '@Reducers/vehicle/actions';
import { TOGGLE_ADD_VEHICLE_MODAL, TOGGLE_SPECIAL_MODIFICATION_VEHICLE_MODAL } from '@Reducers/modals/actions';
import routes, { getRoutesAs, isRouteCatalog } from '@Routes';
import { updateURLParams } from '@Helpers/webparts';
import { getVehicleFOCodes, vehicleExistInOrganisation } from '@Apis/vehicle';
import { nonSavedVehicleInformation } from '@Reducers/catalogue/models';
import { getVehicleVinValidation } from '@Apis/index';
import {
	UPDATE_CATALOGUE_PATH,
	SET_NONSAVED_VEHICLE,
	SET_SEARCHED_VEHICLE_STATUS,
	RESET_CATALOGUE_PATH,
} from '@Reducers/catalogue/actions';
import { TRUNCATE_OMISSION } from '@Constants/print';
import { MAX_LENGTH_TOOLBAR_STRING } from '@Constants/retailer';
import { SearchedVehicleStatus } from '@Constants/catalog';
import { Patterns, PromiseStatus, ResponseCode } from '@Constants/common';
import { AddVehicleResponseCode } from '@Constants/vehicle';

const getVehicleDataSubLabel = vehicle => {
	const customerName = vehicle?.customerName || '';
	const plateNumber = vehicle?.plateNumber || '';
	const separator = customerName && plateNumber ? ',' : '';
	const customerAndPlateNumberString =
		customerName || plateNumber ? ` (${customerName}${separator}${plateNumber})` : '';

	return vehicle.vin + customerAndPlateNumberString;
};

const getVehicleList = (list, selectedVin) => {
	const index = list?.findIndex(car => car.vin === selectedVin);
	if (index !== -1) {
		const firstVehicle = list?.splice(index, 1)[0];
		list?.unshift(firstVehicle);
	}

	const vehicleList = list?.map(vehicle => ({
		id: vehicle.vin,
		label: vehicle.model,
		subLabel: getVehicleDataSubLabel(vehicle),
	}));
	return vehicleList;
};

const VINDropdown = () => {
	const router = useRouter();
	const { market, model, modelName, vin, pageSource } = router.query;
	const { t } = useTranslation(['common', 'vehicles']);
	const dispatch: AppDispatch = useDispatch();
	const language = useSelector<RootState, string>(state => state.configuration.language);
	const recentlyOpenedVehicles = useSelector<RootState, any[]>(state => state.vehicle.recentlyOpenedVehicles);
	const vehicleMenuError = useSelector<RootState, boolean>(state => state.vehicle.recentlyOpenedVehiclesError);
	const nonSavedVehicleInformation = useSelector<RootState, nonSavedVehicleInformation>(
		state => state.catalogue.nonSavedVehicleInformation
	);
	const searchedVehicleStatus = useSelector<RootState, SearchedVehicleStatus>(
		state => state.catalogue.searchedVehicleStatus
	);
	const options = useMemo(() => getVehicleList(recentlyOpenedVehicles, vin), [recentlyOpenedVehicles, vin]);
	const selection = (() => {
		if (nonSavedVehicleInformation)
			return {
				id: '0',
				label: truncate(nonSavedVehicleInformation?.modelName, {
					length: MAX_LENGTH_TOOLBAR_STRING,
					omission: TRUNCATE_OMISSION,
				}),
				subLabel: nonSavedVehicleInformation?.vin,
			};
		if (model) {
			const subLabelWithoutLetter = (model as string).replace(/^[A-Za-z]/, '');
			return {
				id: '-1',
				label: truncate(modelName as string, {
					length: MAX_LENGTH_TOOLBAR_STRING,
					omission: TRUNCATE_OMISSION,
				}),
				subLabel: subLabelWithoutLetter,
			};
		}
		return {
			...options?.[0],
			label: truncate(options?.[0]?.label, {
				length: MAX_LENGTH_TOOLBAR_STRING,
				omission: TRUNCATE_OMISSION,
			}),
		};
	})();

	const onClickShowAll = () => {
		const homePath = getRoutesAs(routes.HOME, { market });
		router.push(routes.HOME, homePath);
	};

	const handleVehiclePageChange = async item => {
		router.push(router.pathname, getRoutesAs(router.pathname, { market, vin: item.id, shallow: false }));
		getVehicleFOCodes(item.id).then(foCodes => {
			if (foCodes.length > 0) dispatch(TOGGLE_SPECIAL_MODIFICATION_VEHICLE_MODAL(true, foCodes));
		});
	};

	const handleCatalogueVINChange = async item => {
		await updateURLParams({
			...(pageSource && { pageSource: pageSource as string }),
			vin: item.id,
		});
		dispatch(SET_NONSAVED_VEHICLE(null));
		dispatch(SET_SEARCHED_VEHICLE_STATUS(SearchedVehicleStatus.NONE));
	};

	const handleVehicleItemClick = async (item: DropdownOption) => {
		if (item.id !== vin) {
			if (isRouteCatalog()) handleCatalogueVINChange(item);
			else handleVehiclePageChange(item);
			dispatch(GET_VEHICLE_DETAILS(item.id));
		}
	};

	const validateVIN = async vin => {
		// Trim the term and check if it's empty
		const vinTrimmed = vin.trim();
		if (!vinTrimmed) return;

		// Check if the term matches VIN format
		const termVIN = vinTrimmed.match(Patterns.VIN_LENGTH_PATTERN);
		if (!termVIN) return;

		// Fetch vehicle validation and organization information
		const [vinValidationRes, vinOrganizationRes] = await Promise.allSettled([
			getVehicleVinValidation(vinTrimmed),
			vehicleExistInOrganisation(vinTrimmed),
		]);

		// Extract necessary information from responses
		const unsupportedVIN =
			vinValidationRes.status === PromiseStatus.FULFILLED &&
			vinValidationRes.value?.responseCode === ResponseCode.FORBIDDEN;
		const registeredInOrganization =
			vinOrganizationRes.status === PromiseStatus.FULFILLED && vinOrganizationRes.value?.isVehicleExist;
		const datacardExist =
			vinValidationRes.status === PromiseStatus.FULFILLED &&
			vinValidationRes.value?.responseCode !== ResponseCode.DATACARD_NOT_FOUND;

		// Check if the vehicle is supported
		if (unsupportedVIN) return;

		// Dispatch action if vehicle is old
		if (vinOrganizationRes.status === PromiseStatus.FULFILLED && vinOrganizationRes.value?.isOldVehicle) {
			dispatch(GET_VEHICLE_DETAILS(vinTrimmed));
		}

		// Handle different scenarios based on organization information
		if (registeredInOrganization) {
			dispatch(SET_SEARCHED_VEHICLE_STATUS(SearchedVehicleStatus.SAVED));
		} else {
			dispatch(GET_VEHICLE_INFO(vinTrimmed, datacardExist)).then(res => {
				if (res.responseCode === AddVehicleResponseCode.SUCCESS) {
					dispatch(SET_NONSAVED_VEHICLE({ modelName: res.data, vin: vinTrimmed }));
					dispatch(SET_SEARCHED_VEHICLE_STATUS(SearchedVehicleStatus.NONSAVED));
				}
			});
		}
	};

	const updateCataloguePath = () => {
		if (vin)
			dispatch(
				UPDATE_CATALOGUE_PATH({
					vin: vin as string,
					model: truncate(options[0]?.label, {
						length: MAX_LENGTH_TOOLBAR_STRING,
						omission: TRUNCATE_OMISSION,
					}),
				})
			);
		else if (model) dispatch(UPDATE_CATALOGUE_PATH({ model: `${model} (${modelName})` }));
	};

	const getVehicleDetails = async () => {
		// When page is refreshed, validation should run first to determine if VIN is saved. If NONE wait for validation
		if (vin && searchedVehicleStatus === SearchedVehicleStatus.NONSAVED) {
			await dispatch(GET_NON_SAVED_VEHICLE_DETAILS(vin as string));
		} else if (vin) {
			await dispatch(GET_VEHICLE_DETAILS(vin as string));
		}
		dispatch(SET_RECENTLY_OPENED_VEHICLES());
	};

	useEffect(() => {
		if (searchedVehicleStatus === SearchedVehicleStatus.NONE && vin) {
			validateVIN(vin);
		}
		return () => {
			dispatch(SET_NONSAVED_VEHICLE(null));
			dispatch(SET_SEARCHED_VEHICLE_STATUS(SearchedVehicleStatus.NONE));
			dispatch(RESET_CATALOGUE_PATH());
		};
	}, []);

	useEffect(() => {
		// options has vehicle model label to be put in catalogue path
		if (options && options.length > 0) {
			updateCataloguePath();
		}
	}, [options]);

	useEffect(() => {
		getVehicleDetails();
	}, [vin, language]);

	return (
		<>
			{vehicleMenuError && <VehicleLoadingError />}
			{!isEmpty(options) && (
				<div className="vin-dropdown">
					<AdvancedDropdown
						key={vin as string}
						options={options}
						value={selection}
						optionsTitle={t('vehicles:recently-opened')}
						optionsFootnoteChild={
							<TextButton
								className="train p-h-2 p-v-4"
								color={TextColors.BLACK}
								hoverType={HoverTypes.AZURE}
								underline
								onClick={onClickShowAll}
							>
								<span className="fs-16">{t('vehicles:all-vehicles')}</span>
							</TextButton>
						}
						actionElement={
							nonSavedVehicleInformation &&
							searchedVehicleStatus === SearchedVehicleStatus.NONSAVED && (
								<TextButton
									className="train p-h-2 p-v-4"
									color={TextColors.AZURE}
									onClick={e => {
										e.stopPropagation();
										dispatch(TOGGLE_ADD_VEHICLE_MODAL(nonSavedVehicleInformation.vin, true));
									}}
								>
									<span className="fs-16 DaimlerCS-Bold">{t('common:save')}</span>
								</TextButton>
							)
						}
						onSelect={handleVehicleItemClick}
					/>
				</div>
			)}
		</>
	);
};

const VehicleLoadingError = () => {
	const { t } = useTranslation(['vehicles']);
	return (
		<div className="disp-flex p-v-5 p-l-2">
			<Icon className="m-h-1" variant={IconVariant.BLOODRED} type={IconType.ERROR2} />
			<p>{t('vehicles:recently-loading-error')}</p>
		</div>
	);
};

export default VINDropdown;
