import routes from '@Routes';

export const HIDE_NEWSLETTER = [routes.FAQ_CATEGORY];

export enum FooterGroups {
	TERMS_OF_USAGE = 'TERMS_OF_USAGE',
	DATA_PROTECTION = 'DATA_PROTECTION',
	COOKIES = 'COOKIE_SETTINGS',
	GMBH = 'GMBH',
	NOTICE_ON_DATA_SECURITY_B2B = 'NOTICE_ON_DATA_SECURITY_B2B',
	LEGAL_NOTICE = 'LEGAL_NOTICE',
}

export enum FooterLinkType {
	GROUP,
	ITEM,
}

export enum FooterOrder {
	NEWS = 'NEWS',
	LINKS = 'LINKS',
	HELP = 'HELP',
	ETBIS = 'ETBIS',
	OTHER_INFO = 'OTHER_INFO',
	B2B_CONNECT_APP = 'B2B_CONNECT_APP',
	MORE = 'MORE',
}

export enum InternalLinksOrder {
	COMMUNICATION_EVENT = 'COMMUNICATION_EVENT',
	RMI = 'RMI',
	DIAGNOSIS = 'DIAGNOSIS',
	MOBILE_APPS = 'MOBILE_APPS',
	WORKSHOP_EQUIPMENT = 'WORKSHOP_EQUIPMENT',
	DATA_PRODUCTS = 'DATA_PRODUCTS',
	PARTS = 'PARTS',
}
