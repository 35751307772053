import { useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from '@Lib/i18n';
import { IconType, IconVariant } from '@isp/icon';
import LinkWithIcon from '@isp/link-with-icon';
import routes, { getRoutesAs } from '@Routes';
import B2BConnectLogo from '@Static/images/b2b-connect-logo-black.svg';
import NetPriceToggleButton from '@Shared/header/StickyToolbar/NetPriceToggle';
import RetailerDropdown from '@Shared/header/StickyToolbar/RetailerDropdown';
import VINDropdown from '@Shared/header/StickyToolbar/VINDropdown';
import { useResize, useScroll } from '@Helpers/hooks/useEvent';
import { getRetailersStates } from '@Helpers/retailer/GetRetailersStates';
import { AppDispatch, RootState } from '@Redux';
import { Retailer } from '@Reducers/organization/models';
import { SET_STICKY_TOOLBAR_TOP_DISTANCE } from '@Reducers/sections/actions';
import ISPUser from '@Shared/feature/ISPUser';
import { B2B_LOGO_IMAGE_HEIGHT, B2B_LOGO_IMAGE_WIDTH } from '@Constants/header';
import { NotificationTheme, NotificationsDirection } from '@Constants/notifications';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import { Markets } from '@Constants/market';
import { CATALOGUE_PAGE_SOURCES } from '@Constants/catalog';
import { MARKETS_NOT_HAVING_LIST_PRICES } from '@Constants/shop';
import { DealerStatuses } from '@Constants/retailer';
import BasketButton from './BasketButton';
import PartsValidation from './PartsValidation';
import './index.scss';

const NotificationSection = dynamic(() => import('@Shared/header/NotificationSection'), { ssr: false });

const StickyToolbar = ({
	showVinDropdown = false,
	showBackToVehicles = false,
	showBackToCatalogueEntry = false,
	showRetailerDropdown,
	showNetPriceToggle,
	showBasketButton = true,
	showNotifications = true,
}) => {
	const router = useRouter();
	const dispatch: AppDispatch = useDispatch();
	const elementRef = useRef<HTMLDivElement>(null);
	const dealersError = useSelector<RootState, boolean>(state => state.organization.dealersError);
	const dealers = useSelector<RootState, Retailer[]>(state => state.organization.dealers);
	const { isPreferredRetailerAvailable } = getRetailersStates(dealers);
	const { market, pageSource } = router.query;
	const sourcePage = pageSource || CATALOGUE_PAGE_SOURCES.VEHICLES;
	const toggleNetPriceVisibility = !MARKETS_NOT_HAVING_LIST_PRICES.includes(market as Markets) && showNetPriceToggle;
	const approvedRetailers = dealers && dealers.filter(d => d.status === DealerStatuses.APPROVED);
	const approvedRetailerCount = approvedRetailers?.length || 0;
	const retailerDropdownVisible = showRetailerDropdown && approvedRetailerCount > 0;
	const showB2BIcon = !showBackToVehicles && !showBackToCatalogueEntry;

	const calculateDistance = () => {
		if (elementRef.current) {
			const rect = elementRef.current.getBoundingClientRect();
			dispatch(SET_STICKY_TOOLBAR_TOP_DISTANCE(rect.top));
		}
	};

	useResize(calculateDistance, { init: true });
	useScroll(calculateDistance, { init: true });

	useEffect(() => {
		calculateDistance();
	}, []);

	return (
		<div
			ref={elementRef}
			className="sticky-toolbar bg-white disp-flex grid--item-gap-5 train__space-between p-h-2 h-60-px"
		>
			<div className="disp-flex train__center">
				{showBackToVehicles && sourcePage === CATALOGUE_PAGE_SOURCES.VEHICLES && <BackToVehicles />}
				{showBackToCatalogueEntry && sourcePage === CATALOGUE_PAGE_SOURCES.BAUMUSTER && <BackToCatalogueEntry />}
				{showB2BIcon && (
					<Image src={B2BConnectLogo} height={B2B_LOGO_IMAGE_HEIGHT} width={B2B_LOGO_IMAGE_WIDTH} alt="B2B Connect" />
				)}
				{showVinDropdown && (
					<div className="p-h-3">
						<VINDropdown />
					</div>
				)}
			</div>
			<div className="disp-flex train__center p-r-2">
				{showNotifications && (
					<NotificationSection
						theme={NotificationTheme.DARK}
						direction={retailerDropdownVisible ? NotificationsDirection.RIGHT : NotificationsDirection.LEFT}
					/>
				)}
				<div className="disp-flex grid--item-gap-4 train__center">
					<>
						{showRetailerDropdown && (
							<ISPUser>
								<RetailerDropdown dealers={dealers} dealersError={dealersError} />
							</ISPUser>
						)}
						{toggleNetPriceVisibility && <NetPriceToggleButton />}
						{isPreferredRetailerAvailable && <PartsValidation />}
					</>
					{showBasketButton && <BasketButton />}
				</div>
			</div>
		</div>
	);
};

const BackToVehicles = () => {
	const { t } = useTranslation(['common']);
	const router = useRouter();
	const { market } = router.query;

	const onClick = () => {
		router.push(routes.HOME, getRoutesAs(routes.HOME, { market }));
	};

	return (
		<LinkWithIcon
			className="color-black-two DaimlerCS-Bold fs-16"
			iconType={IconType.ARROW_LEFT}
			onClick={onClick}
			iconVariant={IconVariant.BLACK}
			dataQA={DATA_QA.VEHICLE_DETAILS_TOOLBAR_BACK_TO_VEHICLES_BUTTON}
		>
			{t('common:vehicles')}
		</LinkWithIcon>
	);
};

const BackToCatalogueEntry = () => {
	const { t } = useTranslation(['vehicles']);
	const router = useRouter();
	const { market } = router.query;

	const onClick = async () => {
		router.push(routes.CATALOGUE, getRoutesAs(routes.CATALOGUE, { market }));
	};

	return (
		<LinkWithIcon
			className="color-black-two DaimlerCS-Bold fs-16"
			iconType={IconType.ARROW_LEFT}
			onClick={onClick}
			iconVariant={IconVariant.BLACK}
			dataQA={DATA_QA.VEHICLE_DETAILS_TOOLBAR_BACK_TO_VEHICLES_BUTTON}
		>
			{t('vehicles:catalog-entry')}
		</LinkWithIcon>
	);
};

export default StickyToolbar;
