import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import Icon, { IconSize, IconType } from '@isp/icon';
import routes, { getRoutesAs } from '@Routes';
import { useTranslation } from '@Lib/i18n';
import { RootState } from '@Redux';
import { HeaderSectionType } from '@Reducers/sections/models';
import { TOGGLE_MEGA_MENU } from '@Reducers/modals/actions';
import { getAuthURL } from '@User-operations';
import Feature from '@Shared/feature/Feature';
import Authenticate from '@Shared/header/Authenticate';
import { PushDataToTagManagerForButtons, PushEventToDataLayer } from '@Helpers/google-analytics/pushDataToTagManager';
import { getCustomRoute } from '@Helpers/redirection/getCustomRoute';
import { getClickedMenuItems } from '@Helpers/storage/localStorage';
import InternalLink from '@Shared/links/InternalLink';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import { LegalTabPaths } from '@Constants/legal';
import {
	Buttons,
	Components,
	EventCategories,
	EventLocation,
	getComponentId,
	Pages,
} from '@Constants/google-analytics';
import { Events, FeatureKeys } from '@Constants/common';
import { INITIAL_FAQ_CATEGORY_SLUG } from '@Constants/faq';
import { HomePartialKeys } from '@Constants/home';
import LogoutConfirmation from './LogoutConfirmation';
import BannerNotification from './BannerNotification';
import './index.scss';

const PlatformSearch = dynamic(() => import('@Shared/header/PlatformSearch'), {
	ssr: false,
});
const LanguageDropdown = dynamic(() => import('@Shared/header/LanguageDropdown'), {
	ssr: false,
});

const Header = ({
	hidden = false,
	productInformation = true,
	standardizedNavigation = true,
	search = true,
	customRoute = null,
}) => {
	const router = useRouter();
	const { market } = router.query;
	const [headerHidden, setHeaderHidden] = useState(hidden);
	const { t } = useTranslation(['common']);

	const isUserLoggedIn = useSelector<RootState, boolean>(state => state.user.isUserLoggedIn);
	const isASPUser = useSelector<RootState, boolean>(state => state.user.isASPUser);

	const homeRoute = getCustomRoute(customRoute, routes.HOME);
	const homeRouteAs = getRoutesAs(homeRoute, { market });

	useEffect(() => {
		setHeaderHidden(hidden);
	}, [hidden]);

	return (
		<div data-id={HomePartialKeys.HOME}>
			<BannerNotification market={market as string} />
			<div className="header__container" data-hidden={headerHidden}>
				<div className="header disp-grid grid--item-gap-5 train__center w-100-p">
					{isUserLoggedIn && isASPUser && <div className="header__tag">{t('common:test-user')}</div>}
					<div className="header__logo h-64-px disp-flex">
						<Link
							className="header__logo__img h-100-p w-100-p cursor-pointer"
							href={homeRoute}
							as={homeRouteAs}
							aria-label="Back To Home"
						/>
					</div>
					<LeftContent
						productInformation={productInformation}
						standardizedNavigation={standardizedNavigation}
						customRoute={customRoute}
					/>
					<RightContent search={search} customLegalRoute={customRoute} />
				</div>
			</div>
		</div>
	);
};

const LeftContent = ({ productInformation, standardizedNavigation, customRoute = null }) => {
	const { t } = useTranslation(['common', 'support']);
	const router = useRouter();
	const dispatch = useDispatch();
	const { market } = router.query;
	const showMegaMenu = useSelector<RootState>(state => state.modals.showMegaMenu);
	const isUserLoggedIn = useSelector<RootState>(state => state.user.isUserLoggedIn);
	const { redDotAvailable, menuItems } = useSelector<RootState, HeaderSectionType>(
		state => state.sections.headerContent
	);

	const clickedItems = getClickedMenuItems();
	const navigationRoute = routes.NAVIGATION;
	const navigationRouteAs = getRoutesAs(routes.NAVIGATION, { market });
	const helpRoute = getCustomRoute(customRoute, routes.FAQ_CATEGORY);
	const helpRouteAs = getRoutesAs(helpRoute, { market, '...categories': INITIAL_FAQ_CATEGORY_SLUG });
	const allNewItemsClicked = menuItems
		.flatMap(item => [item, ...(item.children || [])])
		.filter(item => item.newChipToggle)
		.every(item => clickedItems.includes(item.type));
	const [showRedDot, setShowRedDot] = useState(false);

	const onClickProductInformation = e => {
		if (showMegaMenu) return;
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		PushEventToDataLayer(Events.CLICK, {
			category: EventCategories.NAVIGATION,
			location: EventLocation.HEADER,
			label: t('common:product-info'),
		});
		dispatch(TOGGLE_MEGA_MENU({ productInformation }));
	};

	const handleClickLink = (e, name) => {
		PushEventToDataLayer(Events.CLICK, {
			category: EventCategories.NAVIGATION,
			location: EventLocation.HEADER,
			label: name,
		});
		PushDataToTagManagerForButtons((e.target as HTMLInputElement).id, window.location.href);
	};

	useEffect(() => {
		setShowRedDot((!isUserLoggedIn && redDotAvailable) || (isUserLoggedIn && !allNewItemsClicked));
	}, [clickedItems, menuItems]);

	return (
		<div className="header__left-content w-max disp-grid grid--item-gap-5" data-flyout-active={showMegaMenu}>
			{productInformation && (
				<div
					onClick={onClickProductInformation}
					className="DaimlerCS-Bold color-white header-item--standart
				grid--item-gap-1 cursor-pointer disp-flex train__center"
					data-hover-azure
					id={getComponentId([Pages.GLOBAL, Components.HEADER, Buttons.PRODUCT_INFORMATION])}
					data-qa={DATA_QA.PRODUCT_INFORMATION}
					data-red-dot-available={showRedDot}
				>
					<Icon type={IconType.INFO_BOX} />
					{t('product-info')}
				</div>
			)}
			<div
				className="header-item--small train__flex"
				id={getComponentId([Pages.GLOBAL, Components.HEADER, Buttons.PRODUCT_INFORMATION_MOBILE])}
			>
				<Icon
					className="m-l-5"
					clickable
					size={IconSize.SMALL}
					type={IconType.HAMBURGER_MENU}
					id={getComponentId([Pages.GLOBAL, Components.HEADER, Buttons.PRODUCT_INFORMATION_MOBILE])}
					onClick={onClickProductInformation}
					dataQa={DATA_QA.PRODUCT_INFORMATION_MOBILE}
				/>
			</div>
			<div className="header__left-content__language">
				<LanguageDropdown />
			</div>
			<div className="header__left-content__links grid--item-gap-4">
				<InternalLink
					href={helpRoute}
					as={helpRouteAs}
					dataQA={DATA_QA.HEADER_NEED_HELP_LINK}
					className="color-very-light-pink-3 fs-14"
					id={getComponentId([Pages.GLOBAL, Components.NEED_HELP, Buttons.LINK])}
					onClick={e => handleClickLink(e, t('support:online-help'))}
				>
					{t('support:online-help')}
				</InternalLink>
				{standardizedNavigation && (
					<Feature feature={FeatureKeys.STANDARDISED_NAVIGATION}>
						<InternalLink
							href={navigationRoute}
							as={navigationRouteAs}
							dataQA={DATA_QA.HEADER_STANDARDISED_NAVIGATION}
							className="color-very-light-pink-3 fs-14"
							id={getComponentId([Pages.GLOBAL, Components.STANDARDISED_NAVIGATION, Buttons.LINK])}
							onClick={e => handleClickLink(e, t('support:navigation-standardized-navigation'))}
						>
							{t('support:navigation-standardized-navigation')}
						</InternalLink>
					</Feature>
				)}
			</div>
		</div>
	);
};

const RightContent = ({ search, customLegalRoute = null }) => {
	const { t } = useTranslation(['common', 'support']);
	const router = useRouter();
	const { market } = router.query;

	const [returnURL, setReturnURL] = useState('');
	const [isDisplayLogout, setDisplayLogout] = useState(false);
	const isUserLoggedIn = useSelector<RootState>(state => state.user.isUserLoggedIn);
	const showMegaMenu = useSelector<RootState>(state => state.modals.showMegaMenu);

	const legalTabRoute = getCustomRoute(customLegalRoute, routes.LEGAL_TAB);

	const handleClickLink = (e, name) => {
		PushEventToDataLayer(Events.CLICK, {
			category: EventCategories.NAVIGATION,
			location: EventLocation.HEADER,
			label: name,
		});
		PushDataToTagManagerForButtons((e.target as HTMLInputElement).id, window.location.href);
	};

	useEffect(() => {
		if (window) setReturnURL(window.location.pathname);
	}, []);

	return (
		<>
			<div
				className="header__login header__right-content disp-grid  grid--item-gap-5 w-max train__center"
				data-flyout-active={showMegaMenu}
			>
				<div className="header__right-content__links">
					<InternalLink
						href={legalTabRoute}
						as={getRoutesAs(legalTabRoute, {
							market,
							tab: LegalTabPaths.PROVIDER,
						})}
						className="disp-flex train__justify-center color-very-light-pink-3 fs-14 w-max"
						dataQA={DATA_QA.HEADER_IMPRINT}
						id={getComponentId([Pages.GLOBAL, Components.PROVIDER_DATA_PROTECTION, Buttons.LINK])}
						onClick={e => handleClickLink(e, t('common:imprint'))}
					>
						{t('common:imprint')}
					</InternalLink>
				</div>
				<div className="header__right-content__language">
					<LanguageDropdown />
				</div>
				{isUserLoggedIn && search && <PlatformSearch />}
				<Authenticate
					t={t}
					authURL={getAuthURL()}
					returnURL={returnURL}
					isUserLoggedIn={isUserLoggedIn}
					displayLogout={() => setDisplayLogout(true)}
				/>
				{isDisplayLogout && <LogoutConfirmation setDisplayLogout={setDisplayLogout} />}
			</div>
			<div
				className="m-h-3 cursor-pointer header__right-content
				header__right-content__flytout-close disp-flex train__center"
				data-flyout-active={showMegaMenu}
			>
				<Icon type={IconType.CLOSE} />
			</div>
		</>
	);
};

export default Header;
