import { createRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from '@Lib/i18n';
import ISPButton, { ButtonVariant } from '@isp/button';
import ISPIcon, { IconType, IconVariant } from '@isp/icon';
import ISPTooltip, { TooltipPositions, TooltipThemes } from '@isp/tooltip';
import { TOGGLE_PARTS_VALIDATION_INTEGRATION_MODAL } from '@Reducers/modals/actions';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { getComponentId, Pages, Components, Buttons } from '@Constants/google-analytics';
import DATA_QA from '@Constants/style/data-qa-selector.json';

const PartsValidation = () => {
	const { t } = useTranslation(['common']);
	const dispatch = useDispatch();
	const [hovered, setHovered] = useState(false);

	const descriptionRef = createRef<HTMLDivElement>();

	const openPartsValidationModal = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		dispatch(TOGGLE_PARTS_VALIDATION_INTEGRATION_MODAL());
	};

	useEffect(() => {
		if (hovered) {
			ReactTooltip.show(descriptionRef.current);
		} else {
			ReactTooltip.hide(descriptionRef.current);
		}
	}, [hovered]);

	return (
		<div className="parts-validation">
			<div
				data-hover-azure
				data-tip=""
				ref={descriptionRef}
				data-effect="solid"
				data-for={getComponentId([Pages.GLOBAL, Components.HEADER, Buttons.TOGGLE, '-parts-validation-tooltip'])}
				data-qa={DATA_QA.NET_PRICE_TOGGLE}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
			>
				<ISPButton
					onClick={openPartsValidationModal}
					variant={ButtonVariant.DEFAULT}
					id={getComponentId([Pages.GLOBAL, Components.HEADER, Buttons.TOGGLE, '-parts-validation-tooltip'])}
				>
					<ISPIcon type={IconType.UPLOAD} variant={IconVariant.BLACK_TWO} />
				</ISPButton>
			</div>
			{hovered && (
				<ISPTooltip
					theme={TooltipThemes.DARK}
					place={TooltipPositions.BOTTOM}
					id={getComponentId([Pages.GLOBAL, Components.HEADER, Buttons.TOGGLE, '-parts-validation-tooltip'])}
					getContent={() => <p>{t('common:parts-validation-tooltip')}</p>}
				/>
			)}
		</div>
	);
};

export default PartsValidation;
