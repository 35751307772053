import { useState } from 'react';
import Icon, { IconSize, IconType, IconVariant } from '@isp/icon';
import { useTranslation } from '@Lib/i18n';
import ISPError from '@Shared/error';
import useIsUserASP from '@Helpers/hooks/useIsUserASP';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { FeedbackValue } from '@Constants/home';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import { Buttons, Components, getComponentId, Pages } from '@Constants/google-analytics';
import './index.scss';

const InitialFeedback = ({ onThumbClick }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const userASP = useIsUserASP();

	const { t } = useTranslation(['common', 'home']);

	const handleThumbClick = (e, value) => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		if (!userASP) {
			setError(false);
			setLoading(true);
			onThumbClick(value)
				.catch(() => setError(true))
				.finally(() => setLoading(false));
		}
	};
	return (
		<div className="p-v-7 disp-grid grid--item-gap-3">
			<div className="DaimlerCS-Bold fs-16 train train__justify-center">{t('home:feedback-question')}</div>
			<div className="disp-flex train__center train__justify-center grid--item-gap-2">
				<div
					className="h-100"
					id={getComponentId([Pages.GLOBAL, Components.FEEDBACK, Buttons.NO])}
					onClick={e => handleThumbClick(e, FeedbackValue.NEGATIVE)}
					data-hover-azure={!loading}
					data-qa={DATA_QA.FEEDBACK_NEGATIVE}
				>
					<Icon
						className="cursor-pointer"
						type={IconType.THUMB_DOWN}
						size={IconSize.LARGE}
						disabled={loading}
						variant={IconVariant.BLACK_TWO}
					/>
				</div>
				<div
					onClick={e => handleThumbClick(e, FeedbackValue.POSITIVE)}
					id={getComponentId([Pages.GLOBAL, Components.FEEDBACK, Buttons.YES])}
					data-hover-azure={!loading}
					data-qa={DATA_QA.FEEDBACK_POSITIVE}
				>
					<Icon
						className="cursor-pointer"
						type={IconType.THUMB_UP}
						size={IconSize.LARGE}
						disabled={loading}
						variant={IconVariant.BLACK_TWO}
					/>
				</div>
			</div>
			{error && <ISPError />}
		</div>
	);
};

export default InitialFeedback;
