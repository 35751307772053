import get from 'lodash/get';
import { Retailer } from '@Reducers/organization/models';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { getRetailersStates } from './GetRetailersStates';

export const getPreferredRetailersAvailability = (retailers: Retailer[]) => {
	const { preferredRetailer } = getRetailersStates(retailers);
	const { isASPUser } = getOrCreateStore(null).getState().user;
	return get(preferredRetailer, 'hasAvailability', false) || isASPUser;
};

export const getRetailersAvailability = (retailers: Retailer[], retailerId: string) => {
	const { numberOfApprovedRetailers } = getRetailersStates(retailers);
	const { isASPUser } = getOrCreateStore(null).getState().user;
	if (numberOfApprovedRetailers > 0) {
		const retailer = retailers.find(retailer => retailer.id === retailerId);
		return get(retailer, 'hasAvailability', false);
	}
	return isASPUser || false;
};
