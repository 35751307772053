import { createRef, FC, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { useRouter } from 'next/router';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import uniqueId from 'lodash/uniqueId';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Button, { ButtonVariant } from '@isp/button';
import Loading, { LoadingSizes } from '@isp/loading';
import Icon, { IconSize, IconType, IconVariant } from '@isp/icon';
import { InputSubThemes } from '@isp/input';
import ISPTooltip, { TooltipPositions, TooltipThemes } from '@isp/tooltip';
import Price from '@isp/price';
import Stepper, { StepperSize } from '@isp/stepper';
import ISPLink, { LinkVariant } from '@isp/link';
import PartItemFootnotes from '@Shared/webparts/PartItemFootnotes/PartItemFootnotes';
import PartFasterAvailableButton from '@Shared/webparts/PartFasterAvailableButton';
import PartAvailabilityTooltip from '@Shared/tooltips/part-availability-tooltip';
import {
	FasterAvailablePart,
	AlternativePartTypes,
	PartItemModel,
	RelatedPartPackage,
	PartColorOptionsInfo,
	SupplementalPart,
} from '@Reducers/vehicle/models';
import { UPDATE_EXPLOSION_DRAWING_HIGHLIGHTED_PARTS } from '@Reducers/vehicle/actions';
import { AppDispatch, RootState } from '@Redux';
import { Retailer } from '@Reducers/organization/models';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
	BasketDealerInfoSummary,
	CatalogueReference,
	UpdateBasketMultipleItemsRequestBody,
	UpdateWebpartsBasketRequestBody,
} from '@Reducers/shop/models';
import {
	TOGGLE_FAST_AVAILABILITY_MODAL,
	TOGGLE_COLOR_SELECTION_MODAL,
	TOGGLE_ALTERNATE_PART_MODAL,
	TOGGLE_REPLACEMENT_CHAIN_MODAL,
	TOGGLE_MODEL_IDENTIFICATION_MODAL,
} from '@Reducers/modals/actions';
import { UPDATE_BASKET, UPDATE_BASKET_MULTIPLE_ITEMS } from '@Reducers/shop/actions';
import { isRouteMaterialParts } from '@Routes';
import { setMomentLanguage } from '@Helpers/common/date';
import { getRetailersStates } from '@Helpers/retailer/GetRetailersStates';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getPartIconContent, getIsFasterAvailable, getFasterAvailabilityList, getNavContext } from '@Helpers/webparts';
import { getPreferredRetailersAvailability } from '@Helpers/retailer/GetRetailersAvailability';
import useUserSettings from '@Helpers/hooks/useUserSettings';
import {
	getBasketValidationErrorMessage,
	resolveErrorCodeAndParameters,
} from '@Helpers/shop/getBasketValidationErrorMessage';
import {
	hash,
	PushDataToTagManagerForAddToCart,
	PushDataToTagManagerForButtons,
} from '@Helpers/google-analytics/pushDataToTagManager';
import { extractNumericValue } from '@Helpers/shop/price';
import { redirectCataloguePartsPage } from '@Helpers/webparts/goToPartsCatalogue';
import { updateWebpartsBasket } from '@Apis/shop';
import XWISDocumentFootnote from '@Shared/webparts/PartItemFootnotes/XWISDocumentFootnote';
import { getMaterialPartDetails, getPartDetails } from '@Apis/vehicle';
import { Catalog } from '@Reducers/catalogue/models';
import { LocalStorageKeys } from '@Helpers/storage/localStorage';
import { getItemNumericPrice } from '@Helpers/google-analytics/getItemData';
import { getComponentId, Components, Buttons, Pages } from '@Constants/google-analytics';
import { PartAvailability } from '@Constants/shop';
import { OrderType } from '@Constants/orders';
import { STEPPER_MIN_COUNT, WEBPARTS_STEPPER_MAX_COUNT } from '@Constants/product/equipment';
import { Events, DATE_FORMAT_MMM_D_YYYY } from '@Constants/common';
import { ALTERNATE_REMAN_PART_AVAILABLE_MARKETS, DeliveryIconProps } from '@Constants/webparts';
import { FootNoteType, VehicleCatalogFilterOptions } from '@Constants/vehicle';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import './index.scss';

enum PartBasketAdditionPreConditions {
	FOOTNOTE,
	REPLACEMENT,
	ALTERNATIVE,
	COLOR,
	NONE,
}
interface PartItemProps {
	dataQA?: string;
	isHighlighted?: boolean;
	isHighlightedItemPresent?: boolean;
	tooltipIndex: number;
	selectedRetailerAvailability: boolean;
	isPreferredRetailerAvailable: boolean;
	preferredRetailer: Retailer;
	vehiclePart: PartItemModel;
	partPackageError: boolean;
	packageLoading: boolean;
	vin?: string;
	isCampaignPart?: boolean;
	partPackages?: RelatedPartPackage[];
	className?: string;
	hidePosition?: boolean;
	isRelatedPart?: boolean;
	onPartAddedToBasket?: Function;
	onBasketAdd?: () => void;
	webpartsError?: boolean;
	wisDocument?: boolean;
	isMaterialPart?: boolean;
	isPartPriceAvailable?: boolean;
	disableAddButton?: boolean;
	disableAvailabilityButton?: boolean;
	recommendPartsLoading?: boolean;
	onStepperChange?: (val: number) => void;
	isRedirectable?: boolean;
	onRedirectionClick?: () => void;
	eventLocation?: string;
}

const PartItem: FC<PartItemProps> = ({
	dataQA,
	tooltipIndex,
	isHighlighted,
	isHighlightedItemPresent,
	selectedRetailerAvailability,
	isPreferredRetailerAvailable,
	preferredRetailer,
	vehiclePart,
	partPackageError,
	packageLoading,
	partPackages,
	vin,
	isCampaignPart = false,
	className = '',
	hidePosition = false,
	isRelatedPart = false,
	onPartAddedToBasket = () => {},
	onBasketAdd,
	webpartsError = false,
	wisDocument = false,
	isMaterialPart,
	isPartPriceAvailable = true,
	disableAddButton = false,
	disableAvailabilityButton = false,
	recommendPartsLoading = false,
	onStepperChange = () => {},
	isRedirectable = false,
	onRedirectionClick = () => {},
	eventLocation = '',
}) => {
	const { t } = useTranslation(['common', 'shop']);
	const router = useRouter();
	const dispatch: AppDispatch = useDispatch();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const {
		model,
		modelId: queryModelId,
		market,
		aggModelId: modelId,
		aggProductId: productId,
		mainGroup,
		subGroup,
		catalog,
		subGroupModuleId,
	} = router.query;
	const [stepperQuantity, setStepperQuantity] = useState(STEPPER_MIN_COUNT);
	const [errorMessage, setErrorMessage] = useState(null);
	const [uuid] = useState(uniqueId('part-availability-tooltip-'));
	const [part, setPart] = useState<PartItemModel>(vehiclePart);
	const userId = useSelector<RootState, string>(state => state.user.userId);
	const catalogs = useSelector<RootState, Catalog[]>(state => state.catalogue.catalogs);
	const selectedCatalog = catalogs && catalogs.find(catalogItem => catalogItem.productId === (catalog as string));
	const {
		shoppingDetails,
		description,
		descriptionIcons,
		quantity,
		partNumber,
		position,
		title,
		displayName,
		partDetails,
		footNotes,
		alternativePartsTypes,
		replacementChain,
		partPrice: campaignPartPrice,
		availabilities: campaignAvailabilities,
		es1Codes,
		colorInfoAvailable,
		navContext,
		partContext,
		aggProductId: recommendedAggProductId,
		aggTypeId: recommendedAggTypeId,
	} = part;

	const allSupplementaryParts = useSelector<RootState, SupplementalPart[]>(
		state => state.vehicle.allSupplementaryParts
	);
	const language = useSelector<RootState, string>(state => state.configuration.language);
	const addToBasketLoading = useSelector<RootState, boolean>(state => state.shop.addToBasketLoading);
	const updatedProductId = useSelector<RootState, string>(state => state.shop.updatedProductId);

	const showNetPrice = useSelector<RootState, boolean>(state => state.shop.showNetPrice);
	const dealers = useSelector<RootState, Retailer[]>(state => state.organization.dealers);
	const catalogueFilterOptions = useSelector<RootState, VehicleCatalogFilterOptions>(
		state => state.catalogue.catalogueFilterOptions
	);
	const isASPUser = useSelector<RootState, boolean>(state => state.user.isASPUser);

	const footnoteRef = useRef(null);
	const { selectedPartLanguage: selectedCatalogueLanguage } = useUserSettings();
	const availability = isCampaignPart ? campaignAvailabilities?.[0]?.availability : shoppingDetails?.availability;
	const availabilityDetail = isCampaignPart
		? campaignAvailabilities[0]?.availability
		: shoppingDetails?.availability || null;
	const price = isCampaignPart ? campaignPartPrice?.price : shoppingDetails?.price;
	const specialOffer = shoppingDetails?.price?.specialOffer;
	const isSpecialOfferAvailable = specialOffer && specialOffer?.specialOfferDiscountAbsolute;
	const isAddButtonDisabled =
		addToBasketLoading ||
		!stepperQuantity ||
		(!isPreferredRetailerAvailable && !isASPUser) ||
		webpartsError ||
		recommendPartsLoading;
	const partAvailability = availability?.type || PartAvailability.WHITE;
	const retailerAvailability = selectedRetailerAvailability ? partAvailability : PartAvailability.WHITE;
	const { numberOfApprovedRetailers } = getRetailersStates(dealers);
	const isFasterAvailable = numberOfApprovedRetailers > 1 && getIsFasterAvailable(retailerAvailability);
	const { isDiscountGroupVisible } = preferredRetailer?.visibilities || {};
	const tooltipRef = useRef<HTMLDivElement>();
	const infoTooltipRef = useRef<HTMLDivElement>();
	const specialOfferTooltipRef = useRef<HTMLDivElement>();
	const isLegacyColor = (footNotes?.findIndex(footNote => footNote.type === FootNoteType.LEGACY_COLOR) || -1) > -1;
	const showPositionInfo = (!hidePosition && position !== null) || isRelatedPart;
	const vehicleIdentification = !isCampaignPart && (vin || queryModelId);
	const fasterAvailableButtonAvailable =
		updatedProductId !== partNumber && isFasterAvailable && !webpartsError && price;
	const alternatePartsAvailableInMarket = ALTERNATE_REMAN_PART_AVAILABLE_MARKETS.includes(market as string);
	const { aggregateTypeId } = selectedCatalog || {};

	const getCatalogReference = async (): Promise<CatalogueReference> => {
		if (!isRelatedPart) {
			return {
				...(mainGroup && { mainGroupId: mainGroup as string }),
				...(subGroup && { subGroupId: subGroup as string }),
				...(modelId && { aggregateModelId: modelId as string }),
				...(productId && { aggregateProductId: productId as string }),
				...(aggregateTypeId && { aggregateTypeId: aggregateTypeId as string }),
				...(model && { catalogModelId: model as string }),
				...(subGroupModuleId && { moduleId: subGroupModuleId as string }),
				...(catalog && { catalogProductId: catalog as string }),
				isMaterialPart,
			};
		}
		if (navContext) {
			const allOptions = selectedCatalog?.aggregates?.flatMap(aggregate => aggregate.options);
			const option = allOptions?.find(
				option => option.aggregateTypeId === recommendedAggTypeId && option.productId === recommendedAggProductId
			);
			const recommendedAggModelId = option ? option.modelId : null;
			return {
				mainGroupId: navContext[0].id,
				subGroupId: navContext[1].id,
				...(recommendedAggModelId && { aggregateModelId: recommendedAggModelId as string }),
				...(recommendedAggProductId && { aggregateProductId: recommendedAggProductId as string }),
				moduleId: partContext?.moduleId,
			};
		}
		return null;
	};

	const checkPartBasketAdditionPreConditions = (): PartBasketAdditionPreConditions => {
		switch (true) {
			case !isEmpty(es1Codes):
				return PartBasketAdditionPreConditions.FOOTNOTE;
			case !isEmpty(replacementChain?.predecessorParts) || !isEmpty(replacementChain?.successorParts):
				return PartBasketAdditionPreConditions.REPLACEMENT;
			case alternativePartsTypes?.includes(AlternativePartTypes.REMAN_PART) && alternatePartsAvailableInMarket:
				return PartBasketAdditionPreConditions.ALTERNATIVE;
			case colorInfoAvailable || isLegacyColor:
				return PartBasketAdditionPreConditions.COLOR;
			default:
				return PartBasketAdditionPreConditions.NONE;
		}
	};

	const handleStepperChange = val => {
		setStepperQuantity(val);
		onStepperChange(val);
	};

	const getUpdatedPart = async () => {
		let detailsRes;

		if (isRouteMaterialParts()) {
			detailsRes = await getMaterialPartDetails(
				part.partContext?.moduleId,
				part.partContext?.sequenceId,
				selectedCatalogueLanguage
			);
		} else {
			detailsRes = await getPartDetails({
				modelId,
				catalogProductId: selectedCatalog.productId,
				productId,
				moduleId: part.partContext?.moduleId,
				sequenceId: part.partContext?.sequenceId,
				fin: vin,
				filterOptions: catalogueFilterOptions,
			});
		}

		if (!detailsRes) return part;

		const updatedPart = { ...part, partDetails: detailsRes };
		setPart(updatedPart);
		return updatedPart;
	};

	const handleErrorMessages = err => {
		const { errorCode, customParameterId } = resolveErrorCodeAndParameters(err);
		const options = { customParameterId };
		setErrorMessage(getBasketValidationErrorMessage(t, errorCode, options));
	};

	const handeReplacementChain = () => {
		dispatch(
			TOGGLE_REPLACEMENT_CHAIN_MODAL({
				selectedPart: part,
				vin,
			})
		);
	};

	const handleAlternateParts = async () => {
		let updatedPart = part;

		if (isEmpty(partDetails)) updatedPart = await getUpdatedPart();
		if (!isEmpty(updatedPart.partDetails?.alternativeParts)) {
			const selectedPartAlternativesInfo = {
				title,
				description,
				partNumber,
				quantity,
				shoppingDetails,
				alternativeParts: updatedPart.partDetails?.alternativeParts || [],
				vin: vin && !isCampaignPart ? vin : null,
				replacementChain: null,
				...((vin || model) &&
					!isCampaignPart && {
						catalogRef: await getCatalogReference(),
					}),
			};
			dispatch(TOGGLE_ALTERNATE_PART_MODAL(selectedPartAlternativesInfo));
		}
	};

	const handleColorSelection = async () => {
		let updatedPart = part;

		if (isEmpty(partDetails)) updatedPart = await getUpdatedPart();
		if (!isEmpty(updatedPart.partDetails?.colors) || isLegacyColor) {
			const selectedPartColorInfo: PartColorOptionsInfo = {
				colorOptions: updatedPart.partDetails?.colors || [],
				vin: vin && !isCampaignPart ? vin : null,
				isLegacyColor,
				partNumber,
				quantity,
				title,
				shoppingDetails,
				footNotes,
				webpartsError,
				...((vin || model) &&
					!isCampaignPart && {
						catalogRef: await getCatalogReference(),
					}),
			};
			dispatch(TOGGLE_COLOR_SELECTION_MODAL(selectedPartColorInfo));
		}
	};

	const sendAddToBasketEvent = cleanedPartNo => {
		const numericNetPrice = getItemNumericPrice(price?.rawNetPricePerUnit);
		const numericListPrice = getItemNumericPrice(price?.rawListPricePerUnit);

		PushDataToTagManagerForAddToCart({
			location: eventLocation,
			currency: price?.currency || price?.rawListPricePerUnit?.currency?.unit,
			value: numericNetPrice * stepperQuantity,
			items: [
				{
					item_id: cleanedPartNo || hash(vehicleIdentification),
					item_name: displayName || title,
					price: numericNetPrice,
					quantity: stepperQuantity,
					discount: numericListPrice - numericNetPrice,
				},
			],
		});
	};

	const handleDefaultAddToBasket = async () => {
		const cleanedPartNo = partNumber.replace(/\s+/g, '');
		const basketDealerAddress = preferredRetailer?.deliveryAddresses.find(address => address.isPreferred);
		const basketRetailerRequestData: BasketDealerInfoSummary = {
			gssnId: !isASPUser ? preferredRetailer.id : localStorage.getItem(LocalStorageKeys.ASP_TEST_ACCOUNT_GSSN_ID),
			userId,
			companyId: !isASPUser
				? basketDealerAddress.companyId
				: localStorage.getItem(LocalStorageKeys.ASP_TEST_ACCOUNT_COMPANY_ID),
			customerNumber: !isASPUser
				? basketDealerAddress.customerNumber
				: localStorage.getItem(LocalStorageKeys.ASP_TEST_ACCOUNT_CUSTOMER_NUMBER),
		};

		const parentPartsSupplementaryParts = allSupplementaryParts?.filter(item => item.parentPartNo === cleanedPartNo);
		const allPartsWithQuantity = parentPartsSupplementaryParts
			?.map(item => ({
				partNumber: item.partNoFormatted,
				quantity: stepperQuantity,
			}))
			.concat({ partNumber, quantity: stepperQuantity });

		if (!isEmpty(parentPartsSupplementaryParts)) {
			const totalQuantity = allPartsWithQuantity.reduce((accumulator, currentValue) => {
				return accumulator + currentValue.quantity;
			}, 0);

			const requestData: UpdateBasketMultipleItemsRequestBody = {
				finNumber: vin && !isCampaignPart ? vin : '',
				parts: allPartsWithQuantity,
				basketDealer: basketRetailerRequestData,
				...((vin || model) &&
					!isCampaignPart && {
						catalogRef: await getCatalogReference(),
					}),
			};

			dispatch(
				UPDATE_BASKET_MULTIPLE_ITEMS({
					requestData,
					cartType: OrderType.WEBPARTS,
					totalItemCount: totalQuantity,
				})
			)
				.then(() => {
					if (vin) onPartAddedToBasket(partNumber);
					setErrorMessage(null);
					if (onBasketAdd) onBasketAdd();
					sendAddToBasketEvent(cleanedPartNo);
				})
				.catch(handleErrorMessages);
		} else {
			const requestData: UpdateWebpartsBasketRequestBody = {
				finNumber: vin || '',
				partNumber,
				quantity: stepperQuantity,
				basketDealer: basketRetailerRequestData,
				isRecommended: vin ? isRelatedPart : false,
				...((vin || model) &&
					!isCampaignPart && {
						catalogRef: await getCatalogReference(),
					}),
			};

			dispatch(
				UPDATE_BASKET({
					data: requestData,
					addedProductName: title,
					showNotification: true,
					quantity: stepperQuantity,
					useTimeZone: true,
					cartType: OrderType.WEBPARTS,
					updateBasketFunction: updateWebpartsBasket,
				})
			)
				.then(() => {
					if (vin) onPartAddedToBasket(partNumber);
					setErrorMessage(null);
					if (onBasketAdd) onBasketAdd();
					sendAddToBasketEvent(cleanedPartNo);
				})
				.catch(handleErrorMessages);
		}
	};

	const handleAddToBasket = async (e): Promise<void> => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		const preAdditionCondition = checkPartBasketAdditionPreConditions();
		switch (preAdditionCondition) {
			case PartBasketAdditionPreConditions.FOOTNOTE:
				footnoteRef.current.toggleFootnote();
				break;
			case PartBasketAdditionPreConditions.REPLACEMENT:
				handeReplacementChain();
				break;
			case PartBasketAdditionPreConditions.ALTERNATIVE:
				handleAlternateParts();
				break;
			case PartBasketAdditionPreConditions.COLOR:
				handleColorSelection();
				break;
			default:
				handleDefaultAddToBasket();
		}
	};

	const handleFastAvailability = async (e = null) => {
		if (e) PushDataToTagManagerForButtons(e.target.id, window.location.href);
		let updatedPart = part;
		const preAdditionCondition = checkPartBasketAdditionPreConditions();
		if (preAdditionCondition !== PartBasketAdditionPreConditions.NONE) updatedPart = await getUpdatedPart();

		const fasterAvailabilityList = await getFasterAvailabilityList(preferredRetailer, partNumber);
		const selectedFasterAvailabilityItem: FasterAvailablePart = {
			...updatedPart,
			fasterAvailabilityList,
			quantity: stepperQuantity,
			fin: vin && !isCampaignPart ? vin : null,
			fromBasket: false,
			availabilityHint: availabilityDetail?.hint || null,
		};
		dispatch(TOGGLE_FAST_AVAILABILITY_MODAL(selectedFasterAvailabilityItem));
	};

	const formatSpecialOfferDate = (dateString: string) => dayjs(dateString).format(DATE_FORMAT_MMM_D_YYYY);

	const getVehiclePartItemClass = () =>
		classNames(className, 'p-4 fs-16 lh-20 color-black-two vehicle-part', {
			'vehicle-part--grayed-out': !isHighlighted && isHighlightedItemPresent,
			'b-l-4 bc-azure': isHighlighted && isHighlightedItemPresent,
			'bg-white-smoke': isRelatedPart,
		});

	useEffect(() => {
		if (!isNaN(quantity)) setStepperQuantity(quantity);
	}, [quantity]);

	useEffect(() => {
		setMomentLanguage(language);
	}, [language]);

	useEffect(() => {
		setPart(vehiclePart);
	}, [vehiclePart]);

	return (
		<>
			<div className={getVehiclePartItemClass()} data-qa={dataQA} data-show-position={!hidePosition}>
				{showPositionInfo && (
					<div className="train__flex vehicle-part__item__position">{isRelatedPart ? '' : position}</div>
				)}
				<div className="train__flex vehicle-part__item__part-number">{partNumber}</div>
				<div className="disp-flex-column vehicle-part__item__information">
					<div className="disp-flex train__center vehicle-part__item__information__title">
						<PartItemDisplayName part={part} isRedirectable={isRedirectable} onRedirectionClick={onRedirectionClick} />
						<div>
							{descriptionIcons?.find(icon => icon === IconType.INFO) && (
								<div>
									<PartItemTooltip
										icon={IconType.INFO}
										tooltipId={`${IconType.INFO}-${tooltipIndex}`}
										iconTooltipRef={infoTooltipRef}
										information={vehiclePart.tooltip || null}
									>
										<Icon size={IconSize.SMALL} type={IconType.INFO} variant={IconVariant.BLUE} />
									</PartItemTooltip>
								</div>
							)}
						</div>
						<div className="disp-flex">
							{descriptionIcons?.map(
								(icon, index) =>
									icon !== IconType.INFO && (
										<div key={index} className="m-r-1">
											<PartItemTooltip
												icon={icon}
												tooltipId={`${icon}-${tooltipIndex}`}
												iconTooltipRef={createRef<HTMLDivElement>()}
												information={vehiclePart.tooltip || null}
											>
												<Icon key={index} size={IconSize.SMALL} type={icon} variant={IconVariant.BLUE} />
											</PartItemTooltip>
										</div>
									)
							)}
						</div>
					</div>
					<div className="disp-flex train__center">{description}</div>
					{wisDocument && vin && !isASPUser && (
						<XWISDocumentFootnote
							partNumber={partNumber}
							vin={vin}
							partName={displayName}
							id={getComponentId(
								[Pages.VEHICLE, Components.PARTS, Components.FOOTNOTE, Buttons.OPEN, 'wis-document', partNumber],
								true,
								true
							)}
						/>
					)}
					<PartItemFootnotes
						ref={footnoteRef}
						packageLoading={packageLoading}
						partPackageError={partPackageError}
						part={part}
						partPackages={partPackages}
						isLegacyColor={isLegacyColor}
						shoppingDetails={shoppingDetails}
						selectedModelId={queryModelId as string}
						vin={vin && !isCampaignPart ? vin : null}
						setPartDetails={getUpdatedPart}
						webpartsError={webpartsError}
						getCatalogReference={vin && !isCampaignPart ? getCatalogReference : null}
					/>
				</div>
				<>
					<div className="disp-flex-column train vehicle-part__item__price__item">
						{isSpecialOfferAvailable ? (
							<SpecialOfferTooltip
								tooltipId={`${partNumber}-${tooltipIndex}`}
								iconTooltipRef={specialOfferTooltipRef}
								offerStartDate={formatSpecialOfferDate(specialOffer?.specialOfferFrom)}
								offerEndDate={formatSpecialOfferDate(specialOffer?.specialOfferTo)}
								discount={specialOffer?.specialOfferDiscountPercent || 0}
							>
								<div className="disp-flex train__center train__flex-end no-white-space">
									<Icon size={IconSize.SMALL} type={IconType.DISCOUNT} variant={IconVariant.BLUE} />
									<Price
										className="DaimlerCS-Bold color-azure"
										amount={extractNumericValue(showNetPrice ? price?.netPricePerUnit : price?.listPricePerUnit)}
									/>
								</div>
							</SpecialOfferTooltip>
						) : (
							<div className="disp-flex train__center train__flex-end no-white-space">
								<Price
									className="DaimlerCS-Bold"
									amount={extractNumericValue(showNetPrice ? price?.netPricePerUnit : price?.listPricePerUnit)}
								/>
							</div>
						)}
						{showNetPrice && (isDiscountGroupVisible || isASPUser) && (
							<div className="disp-flex train__center train__flex-end no-white-space">
								<Price amount={extractNumericValue(price?.listPricePerUnit) || '-'} />
							</div>
						)}
					</div>
					<span className="disp-flex train__flex-end color-boulder vehicle-part__item__quantity">
						{!isNaN(quantity) ? quantity : STEPPER_MIN_COUNT}
					</span>
					{isPartPriceAvailable && (
						<>
							<div className="vehicle-part__shopping-details vehicle-part__item__shop-details">
								<div className="disp-flex train__justify-center part-quantity-selection">
									<Stepper
										subTheme={InputSubThemes.GRAY}
										minCount={STEPPER_MIN_COUNT}
										maxCount={WEBPARTS_STEPPER_MAX_COUNT}
										value={stepperQuantity}
										onChange={handleStepperChange}
										disabled={webpartsError}
										size={StepperSize.SMALL}
									/>
								</div>
								<div
									onMouseEnter={() => ReactTooltip.show(tooltipRef.current)}
									onMouseLeave={() => ReactTooltip.hide(tooltipRef.current)}
									data-tip=""
									ref={tooltipRef}
									data-for={uuid}
									data-event={Events.CLICK}
									className="disp-flex train__justify-center part-availability p-t-1"
								>
									<Icon
										{...DeliveryIconProps[retailerAvailability]}
										dataQa={[DATA_QA.PART_LIST, DATA_QA.SUFFIXES.ROW, DATA_QA.SUFFIXES.AVAILABILITY].join('-')}
									/>
								</div>
								{availabilityDetail && <PartAvailabilityTooltip id={uuid} hint={availabilityDetail.hint} />}
								<div className="disp-flex part-add-to-basket">
									{updatedProductId === partNumber && <Loading className="p-h-8 m-l-2" size={LoadingSizes.MICRO} />}
									{updatedProductId !== partNumber && !disableAddButton && (
										<Button
											dataQA={[DATA_QA.PART_LIST, DATA_QA.SUFFIXES.ROW, DATA_QA.SUFFIXES.ADD].join('-')}
											className="part-add-to-basket__button p-h-2"
											disabled={isAddButtonDisabled}
											startIconType={IconType.CART}
											variant={ButtonVariant.PRIMARY}
											onClick={handleAddToBasket}
											id={getComponentId(
												[displayName || title, description, Components.EXPLOSION_DRAWING, Buttons.ADD],
												true,
												true
											)}
										>
											<span>{t('common:add')}</span>
										</Button>
									)}
								</div>
								{fasterAvailableButtonAvailable && !disableAvailabilityButton && (
									<div className="disp-flex part-faster-availability">
										<PartFasterAvailableButton
											dataQA={[DATA_QA.PART_LIST, DATA_QA.SUFFIXES.ROW, DATA_QA.SUFFIXES.FASTER_AVAILABLE].join('-')}
											onClick={handleFastAvailability}
											id={getComponentId(
												[
													Pages.VEHICLE,
													Components.EXPLOSION_DRAWING,
													Buttons.FASTER_AVAILABLE,
													'part-list',
													partNumber,
												],
												true,
												true
											)}
										/>
									</div>
								)}
							</div>
						</>
					)}
					{!isEmpty(errorMessage) && (
						<div className="vehicle-part--error disp-flex p-t-1">
							<div className="disp-flex train__justify-center train__center">
								<Icon type={IconType.ERROR2} size={IconSize.SMALLER} variant={IconVariant.BLOODRED} className="m-r-3" />
							</div>
							<p className="DaimlerCS-Regular">{errorMessage}</p>
						</div>
					)}
				</>
			</div>
		</>
	);
};

export const PartItemTooltip = ({ children, iconTooltipRef, icon, tooltipId, information = null }) => {
	const { t } = useTranslation(['vehicles']);
	const getPartItemTooltipContent = () =>
		icon === IconType.INFO ? parse(information || '') : t(getPartIconContent(icon));

	return (
		<>
			<div
				data-tip=""
				ref={iconTooltipRef}
				data-effect="solid"
				data-event={Events.CLICK}
				data-for={tooltipId}
				onMouseEnter={() => ReactTooltip.show(iconTooltipRef.current)}
				onMouseLeave={() => ReactTooltip.hide(iconTooltipRef.current)}
				className="train__justify-center train__center"
			>
				{children}
			</div>
			<ISPTooltip
				theme={TooltipThemes.DARK}
				place={TooltipPositions.BOTTOM}
				id={tooltipId}
				getContent={getPartItemTooltipContent}
			/>
		</>
	);
};

export const SpecialOfferTooltip = ({
	children,
	iconTooltipRef,
	tooltipId,
	offerStartDate,
	offerEndDate,
	discount,
}) => {
	const { t } = useTranslation(['shop']);
	const tooltipContent = () => {
		return (
			<div className="p-2">
				<p className="DaimlerCS-Bold">{t('shop:promotion-discount-with-percentage', { PERCENTAGE: discount })}</p>
				<p>{`${offerStartDate} - ${offerEndDate}`}</p>
			</div>
		);
	};

	return (
		<>
			<div
				data-tip=""
				ref={iconTooltipRef}
				data-effect="solid"
				data-event={Events.CLICK}
				data-for={tooltipId}
				onMouseEnter={() => ReactTooltip.show(iconTooltipRef.current)}
				onMouseLeave={() => ReactTooltip.hide(iconTooltipRef.current)}
				className="cursor-pointer"
			>
				{children}
			</div>
			<ISPTooltip
				theme={TooltipThemes.DARK}
				place={TooltipPositions.TOP}
				id={tooltipId}
				getContent={() => tooltipContent()}
			/>
		</>
	);
};

export const PartItemDisplayName = ({
	part,
	isRedirectable,
	onRedirectionClick,
}: {
	part: PartItemModel;
	isRedirectable: boolean;
	onRedirectionClick: () => void;
}) => {
	const router = useRouter();
	const dispatch: AppDispatch = useDispatch();
	const { selectedPartLanguage } = useUserSettings();
	const catalogueFilterOptions = useSelector<RootState, VehicleCatalogFilterOptions>(
		state => state.catalogue.catalogueFilterOptions
	);
	const {
		model,
		moduleId,
		modelName,
		catalog,
		vin,
		aggModelId: routeModelId,
		aggProductId: routeProductId,
	} = router.query;
	const { displayName, title, partNumber } = part;
	const cleanedPartNo = partNumber.replace(/\s+/g, '');

	const handlePartClick = async e => {
		PushDataToTagManagerForButtons((e.target as HTMLInputElement).id, window.location.href);
		getNavContext({
			cleanedPartNo,
			vin: vin as string,
			selectedPartLanguage,
			catalogueFilterOptions,
			model: model as string,
		}).then(navContext => {
			const { mainGroupId, subGroupId, aggModelId, aggProductId } = navContext;
			if (model) {
				redirectCataloguePartsPage({
					modelName: modelName as string,
					mainGroup: mainGroupId,
					subGroup: subGroupId,
					partNumber: cleanedPartNo,
					catalog: catalog as string,
					aggModelId: aggModelId || (routeModelId as string),
					aggProductId: aggProductId || (routeProductId as string),
					subGroupModuleId: moduleId as string,
					vin: (vin as string) || null,
					model: model as string,
				});

				if (cleanedPartNo) dispatch(UPDATE_EXPLOSION_DRAWING_HIGHLIGHTED_PARTS([cleanedPartNo], true));
			} else if (vin) {
				redirectCataloguePartsPage({
					modelName: modelName as string,
					mainGroup: mainGroupId,
					subGroup: subGroupId,
					partNumber: cleanedPartNo,
					catalog: catalog as string,
					aggModelId: aggModelId as string,
					aggProductId: aggProductId as string,
					subGroupModuleId: moduleId as string,
					vin: vin as string,
					model: model as string,
				});

				if (cleanedPartNo) dispatch(UPDATE_EXPLOSION_DRAWING_HIGHLIGHTED_PARTS([cleanedPartNo], true));
			} else {
				dispatch(TOGGLE_MODEL_IDENTIFICATION_MODAL(cleanedPartNo));
			}
			onRedirectionClick();
		});
	};

	return isRedirectable ? (
		<ISPLink
			onClick={handlePartClick}
			variant={LinkVariant.SECONDARY}
			underline
			className="DaimlerCS-Bold fs-16 color-black-two"
			id={getComponentId([Pages.PARTS, Components.PARTS_ITEM, Buttons.PART_NAME])}
			data-qa={[DATA_QA.PART_SEARCH, DATA_QA.SUFFIXES.TEASER, DATA_QA.SUFFIXES.TITLE].join('-')}
		>
			{displayName || title}
		</ISPLink>
	) : (
		<span className="DaimlerCS-Bold">{displayName || title}</span>
	);
};

const mapStateToProps = state => {
	const { dealers } = state.organization;

	const { isPreferredRetailerAvailable, preferredRetailer } = getRetailersStates(dealers);
	const selectedRetailerAvailability = getPreferredRetailersAvailability(dealers);
	return {
		isPreferredRetailerAvailable,
		preferredRetailer,
		selectedRetailerAvailability,
	};
};

export default connect(mapStateToProps)(PartItem);
