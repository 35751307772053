import { ReactElement } from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import parse from 'html-react-parser';
import { renderToStaticMarkup } from 'react-dom/server';
import CONFIG from '@Umbraco/config.json';
import { client } from '@Umbraco/client';
import { Product, toProduct } from '@Umbraco/product';
import { transform } from '@Umbraco/helpers/transform';
import getHierarchicalDescendants, { filterInvalidItems } from '@Umbraco/helpers';
import { toMetaSettings, MetaSettings } from '@Umbraco/metaData';
import { Image, Link, toImage, toLinkItem } from '@Umbraco/models';
import { IconType } from '@isp/icon';
import { chain } from '@Helpers/common/array';
import { Components } from '@Constants/google-analytics';
import { PartCardType } from '@Constants/parts';

interface Feauture {
	id: string;
	icon: string;
	description: string;
}
interface ProductItem {
	id: string;
	title: string;
	description: string;
	products: Product[];
}
export interface PartsHeaderSection {
	id: string;
	title: string;
	icon: string;
	description: string;
}
export interface ProductStore {
	id: string;
	key: string;
	icon: string;
	title: string;
	subtitle: string;
	description: string;
	content: string;
	productItems: ProductItem;
	productStoreFeatures: Feauture[];
	headerSections: PartsHeaderSection[];
	metaSettings: MetaSettings;
}
export enum PartsType {
	GENUINE_PARTS = 'GENUINE_PARTS',
	REMAN_PARTS = 'REMAN _PARTS',
	STAR_PARTS = 'STAR_PARTS',
}
export enum StarPartsSectionType {
	ADVANTAGES = 'ADVANTAGES',
	PRODUCTS = 'PRODUCTS',
	ORDER = 'ORDER',
	TABLE = 'TABLE',
	FEATURE = 'FEATURE',
	COMPARISON = 'COMPARISON',
	VIDEO = 'VIDEO',
}
export enum GenuinePartsSectionType {
	ADVANTAGES = 'ADVANTAGES',
	PRODUCTS = 'PRODUCTS',
	TABLE = 'TABLE',
	COMPARISON = 'COMPARISON',
	DIVISION = 'DIVISION',
}
export enum RemanPartsSectionType {
	ADVANTAGES = 'ADVANTAGES',
	DIVISION = 'DIVISION',
	TABLE = 'TABLE',
	FEATURE = 'FEATURE',
	PORTFOLIO = 'PORTFOLIO',
	RICH_TEXT = 'RICH_TEXT',
	VIDEO = 'VIDEO',
}
export enum ComparisonPartType {
	GENIUE_PARTS = 'GENIUE_PARTS',
	STAR_PARTS = 'STAR_PARTS',
	REMAN_PARTS = 'REMAN_PARTS',
}
export const ComparisonComponentId = {
	[ComparisonPartType.GENIUE_PARTS]: Components.PARTS,
	[ComparisonPartType.REMAN_PARTS]: Components.REMAN_PARTS,
	[ComparisonPartType.STAR_PARTS]: Components.STAR_PARTS,
};
export enum ImagePosition {
	RIGHT = 'Right',
	LEFT = 'Left',
}

export enum BreakerModuleColor {
	WHITE = 'WHITE',
	GREY = 'GREY',
}

export const BreakerModuleColorMapping = {
	[BreakerModuleColor.WHITE]: 'bg-white color-black-two',
	[BreakerModuleColor.GREY]: 'bg-black-three color-white',
};

export interface PartsAdvantagesSection {
	identifier: StarPartsSectionType;
	title: string;
	subtitle: string;
	iconList: {
		icon: IconType;
		headline?: string;
		description: string;
		annotation?: string;
	}[];
	buttonLabel?: string;
}

export interface VideoSection {
	identifier: RemanPartsSectionType;
	videoContent: string;
	thumbnailImage: Image;
	title: string;
	description: string;
	videoPosition: ImagePosition;
}

export interface RichTextSection {
	identifier: RemanPartsSectionType;
	title: string;
	description: string;
	tableCollapseToggle: boolean;
}
export interface StarPartsTableSection {
	identifier: StarPartsSectionType;
	description: string;
	tableCollapseToggle: boolean;
}
export interface StarPartsOrdersSection {
	identifier: StarPartsSectionType;
	title: string;
	subtitle: string;
	image: Image;
}
export interface StarPartsProductsSection {
	identifier: StarPartsSectionType;
	title: string;
	description: string;
	productList: {
		title: string;
		description: string;
		image: Image;
	}[];
}
export interface StarPartsFeatureSection {
	identifier: StarPartsSectionType;
	title: string;
	description: string;
	image: Image;
	imagePosition: ImagePosition;
	button: Link;
}
export interface StarPartsComparisonSection {
	identifier: StarPartsSectionType;
	title: string;
	bulletList: string[];
	boxes: StarPartsComparisonBox[];
}
export interface StarPartsComparisonBox {
	header: string;
	icon: Image;
	bulletList: string[];
	partType: ComparisonPartType;
}

export type PartSectionType = StarPartsSectionType | GenuinePartsSectionType | RemanPartsSectionType;

export type StarPartsSectionItem =
	| PartsAdvantagesSection
	| StarPartsProductsSection
	| StarPartsOrdersSection
	| StarPartsTableSection
	| StarPartsFeatureSection;
export interface StarPartsFAQ {
	title: string;
	button: Link;
	description: string;
	image: Image;
}
export interface StarPartsPage {
	title: string;
	description: string;
	sections: StarPartsSectionItem[];
	faq: StarPartsFAQ;
}

/* -- Genuine Parts -- */
export interface GenuinePartsAdvantagesSection {
	identifier: GenuinePartsSectionType;
	title: string;
	bulletList: string[];
	videoContent: string;
	thumbnailImage: Image;
}
export interface GenuinePartsProductsSection {
	identifier: GenuinePartsSectionType;
	title: string;
	description: string;
}
export interface GenuinePartsDivisionSection {
	identifier: GenuinePartsSectionType;
	title: string;
	description: string;
	cardList: PartCard[];
}
export interface GenuinePartsComparisonSection {
	identifier: GenuinePartsSectionType;
	title: string;
	bulletList: string[];
	boxes: GenuinePartsComparisonBox[];
}
export interface GenuinePartsComparisonBox {
	header: string;
	icon: Image;
	bulletList: string[];
	partType: ComparisonPartType;
}
export type GenuinePartsSectionItem =
	| GenuinePartsAdvantagesSection
	| GenuinePartsProductsSection
	| GenuinePartsComparisonSection
	| GenuinePartsDivisionSection
	| PartsTableSection;

export interface GenuinePartsPage {
	title: string;
	subtitle: string;
	backgroundImage: Image;
	sections: GenuinePartsSectionItem[];
}

/* -- Reman Parts -- */

export enum RemanCategoryType {
	ENGINES = 'ENGINES',
}
export interface RemanPartsDivisionSection {
	identifier: RemanPartsSectionType;
	title: string;
	description: string;
	cardList: PartCard[];
}
export interface RemanPartsFeatureSection {
	identifier: RemanPartsSectionType;
	title: string;
	description: string;
	image: Image;
}
export interface PartsTableSection {
	identifier: RemanPartsSectionType | GenuinePartsSectionType;
	description: string;
	tableCollapseToggle: boolean;
}
export interface RemanPartsPortfolioSection {
	identifier: RemanPartsSectionType;
	title: string;
	subtitle: string;
	products: PartsProduct[];
}

export interface RemanPartsSuggestedEngine {
	image: Image;
	subtitle: string;
	title: string;
	listPrice: number;
	coreValue: number;
	stock: number;
	partNumber: string;
	description: string;
	model: string;
	finNumber: string;
	availability: PartsAvailability;
}

export interface RemanPartsVehicle {
	identifier: 'CARS' | 'VANS';
	title: string;
	description: string;
	buttonLabel: string;
	image: Image;
}
export interface PartCard {
	identifier?: PartCardType;
	image: Image;
	title: string;
	description: string;
	buttonLabel: string;
	buttonURL?: string;
}
export type RemanPartsSectionItem =
	| PartsAdvantagesSection
	| RemanPartsDivisionSection
	| RemanPartsFeatureSection
	| PartsTableSection
	| RemanPartsPortfolioSection;
export interface RemanPartsPage {
	title: string;
	description: string;
	sections: RemanPartsSectionItem[];
	vehicleTypes: RemanPartsVehicle[];
}
export interface PartsCategoryOverview {
	title: string;
	subtitle: string;
	categories: PartsCategory[];
	products: PartsProduct[];
	pageContent: {
		sections: RemanPartsSectionItem[];
		content: string;
	};
	isModalOpen?: boolean;
	onClickInModal?: (e) => void;
	vehicleType: string;
	searchTerm: string;
	countryInfo: RemanCountryInfo;
	setHeaderBackAvailable: (state) => void;
}

export interface RemanCountryInfo {
	currency: string;
	countryId: number;
}
export interface PartsCategory {
	products: PartsProduct[];
	productCategoryCard: {
		title: string;
		subtitle: string;
		image: Image;
	};
	content: string;
	sections: RemanPartsSectionItem[];
	slug: string;
	categoryIdentifier: string;
}
export interface PartsProduct {
	content: {
		identifier: string;
		showCaseImage: Image;
		title: string;
		subtitle: string;
		hiddenInCategoryLevel: boolean;
	};
	pageContent: {
		image: Image;
		metaDescription: string;
		description: string;
	};
	price?: number;
	coreValue?: number;
	partNumber?: string;
	availabilityInfo?: PartsAvailability;
	slug?: string;
}

export interface PartsAvailability {
	partNumber: string;
	quantity: number;
	availabilityState: number;
	availabilityColorSymbol: string;
	availabilityHint: string;
}

export const toFeature = item => ({
	id: item._id,
	icon: item.productStoreFeatureIcon,
	description: item.productStoreFeatureDescription,
});
const toProductItems = item => ({
	id: item._id,
	title: get(item, 'title', ''),
	description: get(item, 'description', ''),
	products: get(item, 'children', []).map(toProduct),
});
const toHeaderSection = item => ({
	id: item._id,
	title: get(item, 'title', ''),
	icon: get(item, 'icon', ''),
	description: renderToStaticMarkup(parse(get(item, 'description', ''), { replace: transform }) as ReactElement),
});
const toProductStore = item => ({
	id: item._id,
	key: item.identifier,
	icon: item.icon,
	title: item.title,
	subtitle: item.subtitle,
	description: item.description,
	content: renderToStaticMarkup(parse(item.pageContent, { replace: transform }) as ReactElement),
	productItems: toProductItems(get(item, 'productItems', [])),
	productStoreFeatures: get(item, 'productStoreFeatures.children', []).map(toFeature),
	headerSections: get(item, 'headerSections', []).map(toHeaderSection),
	metaSettings: toMetaSettings(item.metaSettings),
});
const toGenuinePartsPage = item => ({
	title: get(item, 'title', ''),
	subtitle: get(item, 'subtitle', ''),
	backgroundImage: toImage(item.backgroundImage),
	metaSettings: toMetaSettings(item.metaSettings),
	sections: get(item, 'sections', []),
});
const toGenuineProductOverview = item => ({
	identifier: get(item, 'identifier', ''),
	heading: get(item, 'heading', ''),
	title: get(item, 'title', ''),
	subtitle: get(item, 'subtitle', ''),
	content: renderToStaticMarkup(parse(get(item, 'content', ''), { replace: transform }) as ReactElement),
	sections: get(item, 'sections', []),
	categories: chain(item.children || [])
		.filter(item => item.contentTypeAlias === 'genuinePartsCategoryPage')
		.map(c => toGenuineProductDetails(c, get(item, '_url', ''), item.children))
		.value(),
	pageInfo: chain(item.children || [])
		.filter(item => item.contentTypeAlias === 'productItems')
		.map(c => ({ title: get(c, 'title', ''), description: get(c, 'description', '') }))
		.value(),
	metaSettings: toMetaSettings(item.metaSettings),
});
const toGenuineProductCategory = item => {
	const categories = chain(item.children || [])
		.filter(item => item.contentTypeAlias === 'genuinePartsCategoryPage')
		.map(c => toGenuineProductDetails(c, get(item, '_url', ''), item.children))
		.value();
	return {
		vehicleTypes: chain(item.children || [])
			.filter(item => item.contentTypeAlias === 'genuinePartsVehiclePage')
			.map(c => {
				return {
					vehicleType: c.identifier,
					vehicleTypeId: c._id,
					categories: categories.filter(child => child.parentId === c._id),
				};
			}),
		metaSettings: toMetaSettings(item.metaSettings),
	};
};
const toGenuineProductDetails = (item, pageUrl, children) => {
	const productItems = children.filter(
		child => child.contentTypeAlias === 'productItems' && child.parentId === item._id
	);
	const productItemsId = get(productItems, '[0]._id');

	return {
		slug: item._url?.replace(pageUrl, '').replace('pages/product-store-pages/genuine-parts/', '').replace(/\/$/, ''),
		productCategoryCard: {
			title: get(item, 'title', ''),
			subtitle: get(item, 'subtitle', ''),
			image: toImage(item.image),
		},
		content: get(item, 'content', ''),
		categoryIdentifier: get(item, 'identifier', ''),
		sections: get(item, 'sections', []),
		id: get(item, '_id', ''),
		metaSettings: toMetaSettings(item.metaSettings),
		parentId: get(item, 'parentId', ''),
		products: chain(children || [])
			.filter(item => item.contentTypeAlias === 'genuinePartsProduct')
			.filter(product => product.parentId === productItemsId)
			.map(c => toPartsProduct(c, get(item, '_url', ''), item.identifier))
			.value(),
	};
};
const toTradeClubPage = item => ({
	title: get(item, 'title', ''),
	content: renderToStaticMarkup(parse(item.content, { replace: transform }) as ReactElement),
	media: toImage(item.media),
	button: get(item, 'button[0]') || toLinkItem(item.button[0]),
	metaSettings: toMetaSettings(item.metaSettings),
});
const toStarPartsPage = item => ({
	title: get(item, 'title', ''),
	description: get(item, 'description', ''),
	metaSettings: toMetaSettings(item.metaSettings),
	faq: {
		button: get(item, 'faqButton[0]') || toLinkItem(item.faqButton[0]),
		description: get(item, 'faqDescription', ''),
		image: toImage(get(item, 'faqImage', '')),
		title: get(item, 'faqTitle', ''),
	},
	sections: get(item, 'sections', []),
});
const toRemanPartsPage = item => ({
	title: get(item, 'title', ''),
	description: get(item, 'description', ''),
	metaSettings: toMetaSettings(item.metaSettings),
	vehicleTypes: get(item, 'vehicleTypes', []),
	sections: get(item, 'sections', []),
	slug: item._url,
});
const toRemanVehiclePage = item => ({
	identifier: get(item, 'identifier', ''),
	heading: get(item, 'heading', ''),
	title: get(item, 'title', ''),
	subtitle: get(item, 'subtitle', ''),
	content: renderToStaticMarkup(parse(get(item, 'content', ''), { replace: transform }) as ReactElement),
	sections: get(item, 'sections', []),
	categories: chain(item.children || [])
		.filter(item => item.contentTypeAlias === 'remanCategoryPage')
		.map(c => toRemanPartsCategory(c, get(item, '_url', ''), item.children))
		.value(),

	metaSettings: toMetaSettings(item.metaSettings),
});
const toRemanPartsCategory = (item, pageUrl, children) => {
	const productItems = children.filter(
		child => child.contentTypeAlias === 'productItems' && child.parentId === item._id
	);
	const productItemsId = get(productItems, '[0]._id');

	return {
		slug: item._url?.replace(pageUrl, '').replace(/\/$/, ''),
		productCategoryCard: {
			title: get(item, 'title', ''),
			subtitle: get(item, 'subtitle', ''),
			image: toImage(item.image),
		},
		content: get(item, 'content', ''),
		categoryIdentifier: get(item, 'identifier', ''),
		sections: get(item, 'sections', []),
		id: get(item, '_id', ''),
		metaSettings: toMetaSettings(item.metaSettings),
		parentId: get(item, 'parentId', ''),
		products: chain(children || [])
			.filter(item => item.contentTypeAlias === 'remanPartsProduct')
			.filter(product => product.parentId === productItemsId)
			.map(c => toRemanPartsProduct(c, get(item, '_url', ''), item.identifier))
			.value(),
	};
};
const toPartsProduct = (item, pageUrl = '', categoryIdentifier = null) => ({
	// eslint-disable-next-line prettier/prettier
	slug: get(item, '_url', '').replace(pageUrl, '').replace('products/', '').replace(/\/$/, ''),
	content: {
		identifier: get(item, 'identifier', ''),
		showCaseImage: toImage(item.showCaseImage),
		title: get(item, 'title', ''),
		subtitle: get(item, 'subtitle', ''),
		hiddenInCategoryLevel: get(item, 'hiddenInCategoryLevel', false),
	},
	pageContent: {
		images: get(item, 'images', []).map(image => toImage(image)),
		view3DLink: get(item, 'view3DLink', ''),
		metaDescription: get(item, 'metaDescription', ''),
		description: get(item, 'description', ''),
	},
	metaSettings: toMetaSettings(item.metaSettings),
	sections: get(item, 'sections', []),
	categoryIdentifier,
});
const toRemanManufacturingProcessPageSectionItem = item => ({
	title: get(item, 'title', ''),
	description: get(item, 'description', ''),
	image: get(item, 'image', ''),
	imagePosition: get(item, 'imagePosition', ImagePosition.LEFT),
});
const toRemanServicePage = item => ({
	identifier: get(item, 'identifier', ''),
	title: get(item, 'title', ''),
	subtitle: get(item, 'subtitle', ''),
	sections: get(item, 'sections', []).map(toRemanManufacturingProcessPageSectionItem),
	metaSettings: toMetaSettings(item.metaSettings),
});

const toRemanPartsProduct = (item, pageUrl = '', categoryIdentifier = null) => ({
	// eslint-disable-next-line prettier/prettier
	slug: get(item, '_url', '').replace(pageUrl, '').replace('products/', '').replace(/\/$/, ''),
	content: {
		identifier: get(item, 'identifier', ''),
		showCaseImage: toImage(item.showCaseImage),
		title: get(item, 'title', ''),
		subtitle: get(item, 'subtitle', ''),
		hiddenInCategoryLevel: get(item, 'hiddenInCategoryLevel', false),
	},
	pageContent: {
		image: toImage(item.image), // TODO: if this is the only difference, it should be mappes to images field
		metaDescription: get(item, 'metaDescription', ''),
		description: get(item, 'description', ''),
	},
	metaSettings: toMetaSettings(item.metaSettings),
	sections: get(item, 'sections', []),
	categoryIdentifier,
});

const toRemanSuggestionPage = item => ({
	title: get(item, 'title', ''),
	description: get(item, 'description', ''),
	sections: get(item, 'sections', []),
	metaSettings: toMetaSettings(item.metaSettings),
});
const toGenuineCustomPage = (items, path) => {
	const page = (items?._embedded?.content || []).find(item => item?.urlPath === path);
	if (!page) return null;

	return {
		breadcrumbTitle: get(page, 'breadcrumbTitle'),
		identifier: get(page, 'identifier', ''),
		title: get(page, 'title', ''),
		subtitle: get(page, 'subtitle', ''),
		sections: get(page, 'content', []).map(toRemanManufacturingProcessPageSectionItem),
		metaSettings: toMetaSettings(page.metaSettings),
	};
};

export function getProductStoreDescendants(key: string, market: string, lang: string) {
	const id = CONFIG[key];
	return client(`/content/${id}/descendants/1/1000`, market, lang)
		.then(async response => {
			const descendants = get(response, '_embedded.content');
			const hierarchical = getHierarchicalDescendants(id, descendants);
			return hierarchical;
		})
		.catch(err => {
			throw err;
		});
}
export function getProductStore(key: string, market: string, lang: string) {
	const id = CONFIG[key];
	return client(`/content/${id}`, market, lang)
		.then(async response => {
			const descendants = await getProductStoreDescendants(key, market, lang);
			const productItems = find(get(descendants, 'children', []), { contentTypeAlias: 'productItems' });
			const productStoreFeatures = find(get(descendants, 'children', []), {
				contentTypeAlias: 'productStoreFeatures',
			});
			return toProductStore({ ...response, productItems, productStoreFeatures });
		})
		.catch(err => {
			console.log('Error while fetching ProductStore', err.code, err.config.url);
			return null;
		});
}

export function getGenuinePartsPage(market: string, lang: string): Promise<GenuinePartsPage> {
	return client(`/content/${CONFIG.GENUINE_PARTS}`, market, lang)
		.then(async response => {
			return toGenuinePartsPage(response);
		})
		.catch(err => {
			throw err;
		});
}

export async function getGenuineProductOverviewPage(market: string, lang: string, id) {
	const vehiclePageReq = client(`/content/${id}`, market, lang);
	const categoriesReq = client(`/content/${id}/descendants/1/1000`, market, lang);
	const [vehiclePageRes, categoriesRes] = await Promise.all([vehiclePageReq, categoriesReq]);
	const hierarchy = get(categoriesRes, '_embedded.content');
	return toGenuineProductOverview({ ...vehiclePageRes, children: filterInvalidItems(hierarchy) });
}

export async function getGenuineProductCategory(market: string, lang: string) {
	const categoriesReq = client(`/content/${CONFIG.GENUINE_PARTS}/descendants/1/1000`, market, lang);
	const [categoriesRes] = await Promise.all([categoriesReq]);
	const hierarchy = get(categoriesRes, '_embedded.content');
	return toGenuineProductCategory({ children: filterInvalidItems(hierarchy) });
}

export function getTradeClubPage(market: string, lang: string) {
	return client(`/content/${CONFIG.TRADE_CLUB}`, market, lang)
		.then(async response => {
			return toTradeClubPage(response);
		})
		.catch(err => {
			throw err;
		});
}
export function getStarPartsPage(market: string, lang: string): Promise<StarPartsPage> {
	return client(`/content/${CONFIG.STAR_PARTS}`, market, lang)
		.then(async response => {
			return toStarPartsPage(response);
		})
		.catch(err => {
			throw err;
		});
}

export function getRemanPartsPage(market: string, lang: string): Promise<RemanPartsPage> {
	return client(`/content/${CONFIG.REMAN_PARTS}`, market, lang)
		.then(async response => {
			return toRemanPartsPage(response);
		})
		.catch(err => {
			throw err;
		});
}

export async function getRemanVehiclePage(id, market: string, lang: string) {
	const vehiclePageReq = client(`/content/${id}`, market, lang);
	const categoriesReq = client(`/content/${id}/descendants/1/1000`, market, lang);
	const [vehiclePageRes, categoriesRes] = await Promise.all([vehiclePageReq, categoriesReq]);
	const hierarchy = get(categoriesRes, '_embedded.content');
	return toRemanVehiclePage({ ...vehiclePageRes, children: filterInvalidItems(hierarchy) });
}

export async function getRemanCategory(id, market, lang: string) {
	const categoryReq = client(`content/${id}/descendants/1/1000`, market, lang);
	const [categoryRes] = await Promise.all([categoryReq]);
	const hierarchy = get(categoryRes, '_embedded.content');
	const pageUrl = get(
		find(hierarchy, item => get(item, 'contentTypeAlias', '') === 'productItems'),
		'_url',
		''
	);
	return chain({ ...filterInvalidItems(hierarchy) })
		.map(c => toRemanPartsProduct(c, pageUrl))
		.value();
}

export function getRemanManufacturingProcess(market: string, lang: string): Promise<any> {
	return client(`/content/${CONFIG.REMAN_MANUFACTURING_PROCESS}`, market, lang)
		.then(async response => {
			return toRemanServicePage(response);
		})
		.catch(err => {
			throw err;
		});
}
export function getRemanEngineSuggestion(market: string, lang: string): Promise<any> {
	return client(`/content/${CONFIG.REMAN_ENGINE_SUGGESTION}`, market, lang)
		.then(async response => {
			return toRemanSuggestionPage(response);
		})
		.catch(err => {
			throw err;
		});
}
export function getRemanAdvantages(market: string, lang: string): Promise<any> {
	return client(`/content/${CONFIG.REMAN_ADVANTAGES}`, market, lang)
		.then(async response => {
			return toRemanServicePage(response);
		})
		.catch(err => {
			throw err;
		});
}

export function getGenuineAdvantages(market: string, lang: string): Promise<any> {
	return client(`/content/${CONFIG.GENUINE_PARTS_ADVANTAGES}`, market, lang)
		.then(async response => {
			// both service pages share the same structure, thus there is no need for unique transformer for genuine parts
			return toRemanServicePage(response);
		})
		.catch(err => {
			throw err;
		});
}

export function getGenuineCustomPage(market: string, lang: string, path: string) {
	return client(`/content/${CONFIG.GENUINE_PARTS_CUSTOM_PAGES}/descendants/1/1000`, market, lang)
		.then(async response => {
			// both service pages share the same structure, thus there is no need for unique transformer for genuine parts
			return toGenuineCustomPage(response, path);
		})
		.catch(err => {
			throw err;
		});
}
