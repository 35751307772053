import { useSelector } from 'react-redux';
import { Retailer } from '@Reducers/organization/models';
import { RootState } from '@Redux';
import { LocalStorageKeys } from '@Helpers/storage/localStorage';

export const usePreferredAddress = () => {
	const isASPUser = useSelector<RootState, boolean>(state => state.user.isASPUser);
	if (!isASPUser) {
		const retailers = useSelector<RootState, Retailer[]>(state => state.organization.dealers);
		const selectedRetailer = retailers?.find(r => r.isPreferred);
		const addresses = selectedRetailer?.deliveryAddresses;
		const preferredAddress = addresses?.find(address => address.isPreferred);
		return { gssnId: selectedRetailer?.id, ...preferredAddress };
	}
	return {
		gssnId: typeof window !== 'undefined' && localStorage.getItem(LocalStorageKeys.ASP_TEST_ACCOUNT_GSSN_ID),
		customerNumber:
			typeof window !== 'undefined' && localStorage.getItem(LocalStorageKeys.ASP_TEST_ACCOUNT_CUSTOMER_NUMBER),
		companyId: typeof window !== 'undefined' && localStorage.getItem(LocalStorageKeys.ASP_TEST_ACCOUNT_COMPANY_ID),
	};
};

export const usePreferredRetailer = () => {
	const retailers = useSelector<RootState, Retailer[]>(state => state.organization.dealers);
	const selectedRetailer = retailers?.find(r => r.isPreferred);
	return selectedRetailer;
};
